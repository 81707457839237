<template>
  <div>
    <el-form
      :model="ruleForm"
      :label-position="labelPosition"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="选择项目" prop="projectId">
            <el-select
              style="width: 100%"
              v-model="ruleForm.projectId"
              placeholder="请选择项目"
            >
              <el-option
                v-for="item in projectList"
                :value="item.projectId"
                :label="item.projectName"
                :key="item.projectId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="场地类型" prop="siteType">
            <el-radio-group class="siteType" v-model="ruleForm.siteType">
              <p><el-radio label="basketball" disabled>篮球场</el-radio></p>
              <p><el-radio label="tennis" disabled>网球场</el-radio></p>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="场地名称" prop="siteName">
            <el-input
              v-model="ruleForm.siteName"
              placeholder="请输入场地名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否开放">
            <el-switch
              v-model="ruleForm.siteStatus"
              active-value="ON"
              inactive-value="OFF"
            ></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="常规开放时间">
            <el-collapse v-model="conventionTime" accordion>
              <el-collapse-item
                v-for="(item, index) in weeks"
                :key="index"
                :title="item.openWeek"
                :name="index"
              >
                <div style="position: relative">
                  <div
                    v-for="(j, jIndex) in item.manageTimeSlotVOS"
                    :key="jIndex + 'o'"
                    style="margin-top: 3px"
                  >
                    <el-time-select
                      style="width: 180px"
                      placeholder="起始时间"
                      v-model="j.startTime"
                      @input="changeTime"
                      :clearable="false"
                      :editable="false"
                      :picker-options="{
                        start: '08:00',
                        step: '00:30',
                        end: '22:00',
                        // maxTime: j.endTime
                        minTime: getMinTime(
                          item.manageTimeSlotVOS[jIndex - 1] &&
                            item.manageTimeSlotVOS[jIndex - 1].endTime
                        ),
                        maxTime: j.endTime,
                      }"
                    >
                    </el-time-select>
                    <el-time-select
                      style="width: 180px"
                      placeholder="结束时间"
                      v-model="j.endTime"
                      @input="changeTime"
                      :clearable="false"
                      :editable="false"
                      :picker-options="{
                        start: '08:00',
                        step: '00:30',
                        end: '22:00',
                        minTime:
                          j.startTime ||
                          (item.manageTimeSlotVOS[jIndex - 1] &&
                            item.manageTimeSlotVOS[jIndex - 1].endTime),
                        maxTime: getMaxTime(
                          item.manageTimeSlotVOS[jIndex + 1] &&
                            item.manageTimeSlotVOS[jIndex + 1].startTime
                        ),
                        // minTime: j.startTime
                      }"
                    >
                    </el-time-select>
                    <div
                      class="mo-input--number"
                      style="width: 150px; display: inline-flex"
                    >
                      <el-input-number
                        :min="0"
                        :max="9999999"
                        :controls="false"
                        @input="changeTime"
                        v-model="j.money"
                      ></el-input-number>
                      <div class="define-append">元</div>
                    </div>
                    <el-button
                      type="text"
                      @click.stop="deleteTimeQuetum(index, jIndex)"
                      style="position: absolute; right: -136px"
                      >删除时段
                    </el-button>
                    <el-button
                      type="text"
                      @click="addTimeQuetum(index, jIndex)"
                      style="position: absolute; left: -5px; bottom: -30px"
                      >添加时段
                    </el-button>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="特殊开放时间">
            <div class="block" style="margin-bottom: 10px">
              <el-date-picker
                v-model="ruleForm.openTime"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-button type="primary" plain @click="addspecialWeeksList"
                >新增日期</el-button
              >
            </div>
            <el-collapse v-model="specialTime" accordion>
              <el-collapse-item
                v-for="(item, index) in specialWeeks"
                :key="index"
                :name="index"
              >
                <template slot="title">
                  {{ item.openTime }}
                  <el-button
                    type="text"
                    @click.stop="handleDelete(index)"
                    style="position: absolute; right: 10px"
                    >删除日期</el-button
                  >
                </template>
                <div style="position: relative">
                  <div
                    style="margin-top: 3px"
                    v-for="(j, jIndex) in item.manageTimeSlotVOS"
                    :key="jIndex + 'j'"
                  >
                    <el-time-select
                      style="width: 180px"
                      placeholder="起始时间"
                      v-model="j.startTime"
                      :clearable="false"
                      :editable="false"
                      :picker-options="{
                        start: '08:00',
                        step: '00:30',
                        end: '22:00',
                        minTime: getMinTime(
                          item.manageTimeSlotVOS[jIndex - 1] &&
                            item.manageTimeSlotVOS[jIndex - 1].endTime
                        ),
                        maxTime: j.endTime,
                      }"
                    >
                    </el-time-select>
                    <el-time-select
                      style="width: 180px"
                      placeholder="结束时间"
                      v-model="j.endTime"
                      :clearable="false"
                      :editable="false"
                      :picker-options="{
                        start: '08:00',
                        step: '00:30',
                        end: '22:00',
                        minTime:
                          j.startTime ||
                          (item.manageTimeSlotVOS[jIndex - 1] &&
                            item.manageTimeSlotVOS[jIndex - 1].endTime),
                        maxTime: getMaxTime(
                          item.manageTimeSlotVOS[jIndex + 1] &&
                            item.manageTimeSlotVOS[jIndex + 1].startTime
                        ),
                        // minTime: startTime
                      }"
                    >
                    </el-time-select>
                    <div
                      class="mo-input--number"
                      style="width: 150px; display: inline-flex"
                    >
                      <el-input-number
                        :min="0"
                        :max="9999999"
                        :controls="false"
                        @input="changeTime"
                        v-model="j.money"
                      ></el-input-number>
                      <div class="define-append">元</div>
                    </div>
                    <el-button
                      type="text"
                      style="position: absolute; right: -136px"
                      @click="deleteTimeQuetum1(index, jIndex)"
                      >删除时段
                    </el-button>
                    <el-button
                      type="text"
                      style="position: absolute; left: -5px; bottom: -30px"
                      @click="addTimeQuetum1(index, jIndex)"
                      >添加时段
                    </el-button>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
        </el-col>
      </el-row>
      <el-col>
        <el-row>
          <div class="SpecialTime">
            <p @click="getHistoryTime" v-if="!flag">历史特殊开放时间</p>
            <el-collapse v-model="historySpecialTime" v-if="flag" accordion>
              <el-collapse-item
                v-for="(item, index) in historyspecialWeeks"
                :key="index"
                :name="index"
              >
                <template slot="title">
                  {{ item.openTime }}
                </template>
                <div style="position: relative">
                  <div
                    style="margin-top: 3px"
                    v-for="(j, jIndex) in item.manageTimeSlotVOS"
                    :key="jIndex + 'j'"
                  >
                    <el-time-select
                      disabled
                      style="width: 180px"
                      placeholder="起始时间"
                      v-model="j.startTime"
                      :picker-options="{
                        start: '08:00',
                        step: '00:30',
                        end: '22:00',
                      }"
                    >
                    </el-time-select>
                    <el-time-select
                      disabled
                      style="width: 180px"
                      placeholder="结束时间"
                      v-model="j.endTime"
                      :picker-options="{
                        start: '08:00',
                        step: '00:30',
                        end: '22:00',
                        minTime: startTime,
                      }"
                    >
                    </el-time-select>
                    <div style="width: 150px; display: inline-flex">
                      <el-input placeholder="" v-model="j.money" disabled>
                        <template slot="append">元</template>
                      </el-input>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
            <p v-if="flag" style="margin-top: 10px" @click="getHistoryTime">
              查看更多
            </p>
          </div>
        </el-row>
      </el-col>
    </el-form>
  </div>
</template>
<script>
import { getHistoryList } from "@/api/ruge/lego/stadium";

export default {
  props: {
    detailsObj: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    projectList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editObj: {},
      labelPosition: "top",
      conventionTime: "-1",
      specialTime: "-1",
      historySpecialTime: "1",
      flag: false,
      current: 1,
      startTime: "",
      endTime: "",
      managedWeeks: [],
      weeks: [
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周一",
          status: false,
          money: "",
          manageTimeSlotVOS: [
            {
              startTime: "",
              endTime: "",
              timeSlot: "",
              money: "",
            },
          ],
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周二",
          status: false,
          money: "",
          manageTimeSlotVOS: [
            {
              startTime: "",
              endTime: "",
              timeSlot: "",
              money: "",
            },
          ],
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周三",
          status: false,
          money: "",
          manageTimeSlotVOS: [
            {
              startTime: "",
              endTime: "",
              timeSlot: "",
              money: "",
            },
          ],
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周四",
          status: false,
          money: "",
          manageTimeSlotVOS: [
            {
              startTime: "",
              endTime: "",
              timeSlot: "",
              money: "",
            },
          ],
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周五",
          status: false,
          money: "",
          manageTimeSlotVOS: [
            {
              startTime: "",
              endTime: "",
              timeSlot: "",
              money: "",
            },
          ],
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周六",
          status: false,
          money: "",
          manageTimeSlotVOS: [
            {
              startTime: "",
              endTime: "",
              timeSlot: "",
              money: "",
            },
          ],
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周日",
          status: false,
          money: "",
          manageTimeSlotVOS: [
            {
              startTime: "",
              endTime: "",
              timeSlot: "",
              money: "",
            },
          ],
        },
      ],
      specialWeeks: [],
      historyspecialWeeks: [],
      ruleForm: {
        projectId: null,
        name: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        RegularOpoen: true,
        openTime: "",
        siteType: "",
        siteName: "",
        siteStatus: "",
      },
      rules: {
        projectId: [{ required: true, message: "请选择项目", trigger: "blur" }],
        name: [{ required: true, message: "请选择场地类型", trigger: "blur" }],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "blur" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
        RegularOpoen: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],
        siteType: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],
        siteName: [
          { required: true, message: "请填写名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    getMinTime(time) {
      if (time) {
        // 08:00 => mm =08, ss=00
        // 08:30 => mm =08, ss=30
        let mm = time.slice(0, 2);
        let ss = time.slice(3, 5);
        if (ss != 0) {
          ss--;
          // console.log(`${mm}:${ss}`,'=====');
          return `${mm}:${ss}`;
        } else {
          mm--;
          ss = 59;
          // console.log(`${mm}:${ss}`,'=====');
          return `${mm}:${ss}`;
        }
      }
      return "";
    },
    getMaxTime(time) {
      // console.log(time,'---------');
      if (time) {
        let mm = time.slice(0, 2);
        let ss = time.slice(3, 5);
        ss++;
        return `${mm}:${ss}`;
      }
      return "";
    },
    changeTime(e) {
      this.$forceUpdate();
    },
    initEditData() {
      let editObj = this.editObj;
      console.log(editObj.siteType, "editObj.siteType");
      this.ruleForm.siteType = editObj.siteType;
      this.ruleForm.siteName = editObj.siteName;
      this.ruleForm.siteStatus = editObj.siteStatus;
      this.ruleForm.projectId = editObj.projectId;
      let weeks = [];
      let specialWeeks = [];
      editObj.manageTimeVOS.map((item) => {
        // 深拷贝 解决影响到父亲传过来的数据 editObj
        let newItem = Object.assign({}, item);
        // 给每个item.manageTimeSlotVOS 增加两个字名
        newItem.manageTimeSlotVOS = newItem.manageTimeSlotVOS.map((j) => {
          return {
            ...j,
            startTime: j.timeSlot.split("-")[0],
            endTime: j.timeSlot.split("-")[1],
          };
        });
        if (item.openTimeType == "normal") {
          weeks.push(newItem);
        } else {
          specialWeeks.push(newItem);
        }
      });
      // 将后台场地常规开放时间去匹配this.weeks里对应的数据,并进行替代
      for (let obj1 of weeks) {
        let index = this.weeks.findIndex(
          (obj2) => obj2.openWeek === obj1.openWeek
        );
        if (index !== -1) {
          this.weeks[index] = {
            ...this.weeks[index],
            ...obj1,
          };
        }
      }
      this.specialWeeks = Object.assign([], specialWeeks);
    },
    addspecialWeeksList() {
      const addList = {
        id: "",
        cardId: "",
        openWeek: "",
        openTime: this.ruleForm.openTime,
        timeSlot: null,
        openTimeType: "special",
        status: false,
        createdBy: "",
        creationDate: "",
        lastUpdatedBy: "",
        lastUpdateDate: "",
        manageTimeSlotVOS: [
          {
            money: "",
            startTime: "",
            endTime: "",
            timeSlot: "",
          },
        ],
      };
      if (this.ruleForm.openTime !== "") {
        this.specialWeeks.push(addList);
        this.ruleForm.openTime = "";
      }
    },
    handleDelete(index) {
      this.specialWeeks.splice(index, 1);
    },
    // 常规开发时间段 增删
    deleteTimeQuetum(index, jIndex) {
      if (jIndex !== 0) {
        this.weeks[index].manageTimeSlotVOS.splice(jIndex, 1);
      } else {
        this.$message("第一条数据不可删噢,请修改~");
      }
      this.conventionTime = "0";
      this.conventionTime = index;
    },
    addTimeQuetum(index, jIndex) {
      const addList = {
        startTime: "",
        endTime: "",
        timeSlot: "",
        money: "",
      };
      let manageTimeSlotVOSList = [];
      this.weeks[index].manageTimeSlotVOS.map((item) => {
        if (
          item.endTime == "" ||
          item.startTime == "" ||
          item.money === undefined ||
          item.money === null ||
          item.money === ""
        ) {
          manageTimeSlotVOSList.push(item);
        }
      });
      if (manageTimeSlotVOSList.length == 0) {
        this.weeks[index].manageTimeSlotVOS.push(addList);
      } else {
        this.$message.error("您上一条数据还没编辑完哦~");
      }
      this.conventionTime = "0";
      this.conventionTime = index;
    },
    // 特殊开发时间段 增删
    deleteTimeQuetum1(index, jIndex) {
      if (jIndex !== 0) {
        this.specialWeeks[index].manageTimeSlotVOS.splice(jIndex, 1);
      } else {
        this.$message("第一条数据不可删噢,请修改~");
      }
    },
    addTimeQuetum1(index, jIndex) {
      const addList = {
        startTime: "",
        endTime: "",
        timeSlot: "",
        money: "",
      };
      let manageTimeSlotVOSList = [];
      this.specialWeeks[index].manageTimeSlotVOS.map((item) => {
        if (
          item.endTime == "" ||
          item.startTime == "" ||
          item.money === undefined ||
          item.money === null ||
          item.money === ""
        ) {
          manageTimeSlotVOSList.push(item);
        }
      });
      if (manageTimeSlotVOSList.length == 0) {
        this.specialWeeks[index].manageTimeSlotVOS.push(addList);
      } else {
        this.$message.error("您上一条数据还没编辑完哦~");
      }
    },
    manageAllWeeks() {
      const allweeks = [...this.weeks, ...this.specialWeeks];
      return (this.managedWeeks = allweeks.map((week) => {
        week.manageTimeSlotVOS = week.manageTimeSlotVOS.map((timeSlot) => {
          if (timeSlot.startTime !== "" && timeSlot.endTime !== "") {
            timeSlot.timeSlot = `${timeSlot.startTime}-${timeSlot.endTime}`;
          } else {
            timeSlot.timeSlot = "";
          }
          return timeSlot;
        });
        return week;
      }));
    },
    getHistoryTime() {
      getHistoryList({
        siteId: this.detailsObj.id,
        current: this.current,
        rowCount: 10,
      }).then((res) => {
        let historyTime = res.rows;
        let historyTimeList = [];
        historyTime.map((item) => {
          let newItem = Object.assign({}, item);
          newItem.manageTimeSlotVOS = newItem.manageTimeSlotVOS.map((j) => {
            return {
              ...j,
              startTime: j.timeSlot.split("-")[0],
              endTime: j.timeSlot.split("-")[1],
            };
          });
          historyTimeList.push(newItem);
        });
        this.flag = true;
        if (this.current === 1) {
          this.historyspecialWeeks = historyTimeList;
        } else {
          this.historyspecialWeeks =
            this.historyspecialWeeks.concat(historyTimeList);
        }
        // 增加当前页数
        this.current++;
        // 如果没有更多数据可供显示，则禁用“查看更多”按钮
        if (!historyTime.length) {
          this.$message("没有更多数据了~");
        }
      });
    },
  },
  watch: {
    detailsObj: {
      handler(newVal) {
        this.editObj = Object.assign({}, newVal);
        this.initEditData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.siteType {
  display: flex;
  width: 100%;

  p:first-child {
    margin-right: 15px;
  }

  p {
    width: 100px;
    padding: 10px 10px;
    border: 1px solid #dcdfe5;
    border-radius: 5px;
  }
}

.SpecialTime {
  margin-top: 10px;

  p {
    font-size: 12px;
    color: #006bd9;
    cursor: pointer;
    margin-bottom: 12px;
  }
}

::v-deep .el-collapse-item__arrow {
  margin: 0;
}

::v-deep .el-collapse-item__content {
  display: flex;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  margin-right: 6px;
}

::v-deep .el-form-item .el-form-item {
  display: flex;
}

::v-deep .el-form--label-top .el-form-item__label {
  margin-right: 11px;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}
/* 自定义数字输入框append  */
.mo-input--number {
  border: 1px solid #dcdfe6;
  width: 100%;
  display: flex;
  border-radius: 4px;
  .el-input-number--mini {
    flex: 1;
  }
  ::v-deep .el-input__inner {
    border: none !important;
  }
  ::v-deep .el-input-number--medium {
    width: 110px;
  }
}

.define-append {
  width: 50px;
  display: inline-block;
  background: #f5f7fa;
  padding: 0px 3px;
  border-left: none;
  height: 36px;
  line-height: 36px;
  color: #909399;
  font-size: 12px;
  text-align: center;
}
</style>
