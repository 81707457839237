import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
/**
 * 场地管理 - 场地配置列表
 * @param params
 */
export function getScheduleList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/manage/queryList',
    method: 'get',
    params,
  });
}

/**
 * 场地管理 - 启用禁用
 * @param params
 */
export function editCardStatus(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/manage/editDisable',
    method: 'get',
    params,
  });
}
/**
 * 场地管理  - 导出功能
 * @param params
 */
export function paymentListExport(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/manage/export',
    method: 'get',
    params,
  });
}
/**
 * 场地管理  - 删除功能
 * @param params
 */
export function paymentListDelete(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/manage/delete',
    method: 'get',
    params,
  });
}

/**
 * 场地管理  - 新增功能
 * @param params
 */
export function paymentListAdd(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/manage/add',
    method: 'post',
    data: params,
  });
}
/**
 * 场地管理  - 编辑功能
 * @param params
 */

export function paymentListEdit(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/manage/edit',
    method: 'post',
    data: params,
  });
}
/**
 * 场地管理  - 获取详情
 * @param params
 */

export function getDetailList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/manage/getDetails',
    method: 'get',
    params,
  });
}

/**
 * 场地管理  - 获取历史特殊日期
 * @param params
 */
export function getHistoryList(params) {
  return request({
    url:
      envInfo.bgApp.customerlegoPath + '/site/manage/queryHistorySpecialDate',
    method: 'get',
    params,
  });
}
/**
 * 次卡管理 - 场地列表
 * @param params
 */
export function getStadiumCardList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/card/queryList',
    method: 'get',
    params,
  });
}
/**
 * 次卡管理 - 启用禁用
 * @param params
 */
export function editStadiumCardStatus(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/card/editDisable',
    method: 'get',
    params,
  });
}
/**
 * 次卡管理 - 编辑
 * @param params
 */
export function editCardList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/card/edit',
    method: 'post',
    data: params,
  });
}
/**
 * 次卡管理 - 获取详情
 * @param params
 */
export function getCardDetails(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/card/getDetails',
    method: 'get',
    params,
  });
}

/**
 * 次卡管理  - 获取历史特殊日期
 * @param params
 */
export function getCardHistoryList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/card/queryHistorySpecialDate',
    method: 'get',
    params,
  });
}
/**
 * 体育场 - 场地日历 - 获取日历时间段
 * @param params
 */
export function getCalendarList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/siteCalendar',
    method: 'post',
    data: params,
  });
}

/**
 * 体育场 - 场地日历 - 获取日历场地列表
 * @param params
 */
export function getPlaceList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/manage/getCalendarSiteList',
    method: 'get',
    params,
  });
}

/**
 * 体育场 - 场地预定
 * @param params
 */
export function addPredetermine(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/web/create',
    method: 'post',
    data: params,
  });
}
/**
 * 体育场 - 详情
 * @param params
 */
export function getCalendarDetails(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/getCalendarDetails',
    method: 'get',
    params,
  });
}
/**
 * 体育场 - 取消订单
 * @param params
 */
export function removeOrder(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/web/cancelOrder',
    method: 'get',
    params,
  });
}

/**
 * 体育场 - 体育预定 获取场地list
 * @param params
 */
export function getSerachTimeList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/getOpenSiteByDateWeb',
    method: 'get',
    params,
  });
}

/**
 * 体育场 - 场地预定列表
 * @param params
 */
export function getStadiumBookingList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/queryList',
    method: 'get',
    params,
  });
}

/**
 * 体育场 - 修改状态
 * @param params
 */
export function editBookingStatus(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/editInvoiceStatus',
    method: 'get',
    params,
  });
}

/**
 * 体育场 - 场地预定
 * @param params
 */
export function addStadiumBooking(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/web/create',
    method: 'post',
    data: params,
  });
}

/**
 * 体育场 - 预定详情
 * @param params
 */
export function getComplaintAdviceDetail(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/getDetails',
    method: 'get',
    params,
  });
}

/**
 * 体育场 - 导出功能
 * @param params
 */
export function stadiumBookinExport(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/export',
    method: 'get',
    params,
  });
}

/**
 * 体育场 - 取消订单
 * @param params
 */
export function removeStadiumBooking(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/site/destined/web/cancelOrder',
    method: 'get',
    params,
  });
}
